<!-- Finder particular de Agencias Afectas -->

<template>
  <div class="agen_F" v-if="schema">
    <v-sheet :elevation="4">
      
      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor"> 

        <!-- Filtro de Busqueda -->
        <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
          <div class="columna" style="width:500px">
            <div class="conflex">
              <v-select
                v-bind="$select"
                v-model="schema.ctrls.afecta_zona.value"
                :label="schema.ctrls.afecta_zona.label"
                :items="ST_zonas"
                item-value="id"
                item-text="name"
                :disabled="zona >0">            
              </v-select>
              
              <v-btn
                v-bind="$cfg.btn.busca"
                @click="buscarExec('buscarAgencia')">            
                  <v-icon dark>mdi-filter</v-icon>
              </v-btn>              
            </div>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.name.value"
              :label="schema.ctrls.name.label"
              @keypress.13="buscarExec('buscarAgencia')">            
            </v-text-field>            
          </div>
        </div>

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">         
            <div style="padding:10px 0 0 10px;">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </div>
          </template>

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>
        </base_Fgrid>      
      </div>
    </v-sheet>

    <!-- Mto  -->      
    <v-dialog
        v-model="modal"
        content-class="modal" persistent>
          <div class="centrado">             
            <component                 
                :is="component_M"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>  

  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const agen_M = () => plugs.groute("agen_M.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, agen_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,
        api:'agen_F',
        stName:'stFagen',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,
     
        headers: {},   
        ST_zonas: []    
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Agencias Afectas";  

        this.headers = {
          header: [
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Nombre", value: "afecta_name", width: "40%" },
            { text: "Empresa", value: "cont1", width: "40%" }            
          ]
        };

        this.Entorno.grid.headers = this.set_headers();

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'agen_M';

        // items adicionales
        // array de zonas 
        this.ST_zonas = this.$store.state.datos_iniciales.zonas;
        this.ST_zonas.unshift({ id: "0", name: "Todas" });
      },  


      //
      ini_schemaCtrls() {        
        this.schema.ctrls.afecta_zona.value= this.zona;        
      }, 


      event_capture(evt) {
        console.log("*** event_capture agen_F. Accion: " + evt.accion + " ***");

        if (this.$isFunction(this[evt.accion])) {
          this[evt.accion](evt);
          return;
        }
        this.$emit("onEvent", evt);
      }
    }
  };
</script>
